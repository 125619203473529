<style lang="scss">
  a {
    color: #212529;
    text-decoration: none !important;
    text-transform: uppercase;
  }
  a:hover {
    text-decoration: underline !important;
  }
  a.link-mail {
    text-transform: none;
  }
  .logo-round {
    width: 146px;
  }
  .logo-talltimbers {
    width: 288px;
  }
</style>
<template>
  <div class='container-fluid mt-2'>
    <!-- <div class='card mb-2'>
      <div class='card-body'>
        <p class='navbar-brand'>Florida Department of Agriculture and Consumer Services</p>
        <p>
          <a href='https://www.fdacs.gov/Forest-Wildfire/Wildland-Fire/Prescribed-Fire' target="_blank">
            <img src='../assets/fdacs.png' alt='Florida Department of Agriculture and Consumer Services Logo' class='logo-round'/>
          </a>
        </p>
        <p>
          <a href='https://www.fdacs.gov/Forest-Wildfire/Wildland-Fire/Prescribed-Fire' target="_blank">Website for More Info</a>
        </p>
        <p>
          <a href='https://contact.fdacs.gov/' target="_blank">Contact Info</a>
        </p>
      </div>
    </div>
    <div class='card mb-2'>
      <div class='card-body'>
        <p class='navbar-brand'>Southern Group of State Foresters</p>
        <p>
          <a href='https://southernforests.org/fire/prescribed-fire-in-the-south/' target="_blank">
            <img src='../assets/sgsf.svg' alt='Southern Group of State Foresters Logo' class='logo-round'/>
          </a>
        </p>
        <p>
          <a href='https://southernforests.org/fire/prescribed-fire-in-the-south/' target="_blank">Website for More Info</a>
        </p>
        <p>
          <a href='https://southernforests.org/contact-us-form-sgsf' target="_blank">Contact Info</a>
        </p>
      </div>
    </div>
    <div class='card mb-2'>
      <div class='card-body'>
        <p class='navbar-brand'>Florida Department of Environmental Protection</p>
        <p>
          <a href='https://floridadep.gov/Parks' target="_blank">
            <img src='../assets/fdep.png' alt='Florida Department of Environmental Protection Logo' class='logo-round'/>
          </a>
        </p>
        <p>
          <a href='https://floridadep.gov/Parks' target="_blank">Website for More Info</a>
        </p>
        <p>
          <a href='https://floridadep.gov/contact-us' target="_blank">Contact Info</a>
        </p>
      </div>
    </div>
    <div class='card mb-2'>
      <div class='card-body'>
        <p class='navbar-brand'>Tall Timbers</p>
        <p>
          <a href='https://talltimbers.org/' target="_blank">
            <img src='../assets/talltimbers.png' alt='Tall Timbers Logo' class='logo-talltimbers'/>
          </a>
        </p>
        <p>
          <a href='https://talltimbers.org/' target="_blank">Website for More Info</a>
        </p>
        <p>
          <a href='https://talltimbers.org/contact-us' target="_blank">Contact Info</a>
        </p>
      </div>
    </div>
    <div class='card mb-2'>
      <div class='card-body'>
        <p class='navbar-brand'>Texas A&amp;M Forest Service</p>
        <p>
          <a href='https://tfsweb.tamu.edu/default.aspx' target='_blank'>
            <img src='../assets/tamfs.png' alt='Texas A and M Forest Service Logo'/>
          </a>
        </p>
        <p>
          <a href='https://tfsweb.tamu.edu/default.aspx' target="_blank">Website for More Info</a>
        </p>
        <p>
          <a href='https://tfsweb.tamu.edu/content/article.aspx?id=19988' target="_blank">Contact Info</a>
        </p>
      </div>
    </div> -->
    <div class='card'>
      <div class='card-body'>
        <p class='navbar-brand'>PBP Site Administrators</p>
        <div class='card card-body'>
          <span>AL - Ethan Barret: <a class='link-mail' href='mailto:ethan.barrett@forestry.alabama.gov'>ethan.barrett@forestry.alabama.gov</a></span>
        </div>
        <div class='card card-body'>
          <span>AR - Marcus "Scott" Reed: <a class='link-mail' href='mailto:marcus.reed@agriculture.arkansas.gov'>marcus.reed@agriculture.arkansas.gov</a></span>
        </div>
        <div class='card card-body'>
          <span>
            FL - John Saddler: <a class='link-mail' href='mailto:john.sadler@fdacs.gov'>john.saddler@fdacs.gov</a>,&nbsp;
            John E. Stys: <a class='link-mail' href='mailto:john.stys@fdacs.gov'>john.stys@fdacs.gov</a></span>
        </div>
        <div class='card card-body'>
          <span>
            KY - Michael Froelich: <a class='link-mail' href='mailto:michael.froelich@ky.gov'>michael.froelich@ky.gov</a>,&nbsp;
            Chris Scott: <a class='link-mail' href='mailto:christopher.scott@ky.gov'>christopher.scott@ky.gov</a>
          </span>
        </div>
        <div class='card card-body'>
          <span>LA - Brett Lane: <a class='link-mail' href='mailto:bret_l@ldaf.state.la.us'>bret_l@ldaf.state.la.us</a></span>
        </div>
        <div class='card card-body'>
          <span>MS - Randy Giachelli: <a class='link-mail' href='mailto:rgiachelli@mfc.ms.gov'>rgiachelli@mfc.ms.gov</a></span>
        </div>
        <div class='card card-body'>
          <span>NC - Gail Bledsoe: <a class='link-mail' href='mailto:gail.bledsoe@ncagr.gov'>gail.bledsoe@ncagr.gov</a></span>
        </div>
        <div class='card card-body'>
          <span>OK - Drew Daily: <a class='link-mail' href='mailto:drew.daily@ag.ok.gov'>drew.daily@ag.ok.gov</a></span>
        </div>
        <div class='card card-body'>
          <span>SC - Drake Carroll: <a class='link-mail' href='mailto:dcarroll@scfc.gov'>dcarroll@scfc.gov</a></span>
        </div>
        <div class='card card-body'>
          <span>TN - Jacqueline Broeker: <a class='link-mail' href='mailto:jacqueline.broeker@tn.gov'>jacqueline.broeker@tn.gov</a></span>
        </div>
        <div class='card card-body'>
          <span>TX - Curt Stripling: <a class='link-mail' href='mailto:cstripling@tfs.tamu.edu'>cstripling@tfs.tamu.edu</a></span>
        </div>
        <div class='card card-body'>
          <span>VA - Fred Turck: <a class='link-mail' href='mailto:fred.turck@dof.virginia.gov'>fred.turck@dof.virginia.gov</a></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Contact',
  components: {},
};
</script>
